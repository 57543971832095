import React, { useEffect } from "react";
import "../pages/styles/team.css";
import "../pages/styles/home.css";
import "./styles/mediaqueries.css";
import logo from "../Assets/images/Logo_Square_mini.png";
import michael from "../Assets/images/michael.png";
import ryan from "../Assets/images/ryan.png";
import david from "../Assets/images/david.png";
import tony from "../Assets/images/tony.png";
import matt from "../Assets/images/matt.png";
import rong from "../Assets/images/rong.png";
import pm from "../Assets/images/pm.png";
import out from "../Assets/images/outsourced.png";
import Footer from "../components/Footer";

const Team = () => {
  useEffect(() => {
    document.title = "Roshpit Champions II | Our Team";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="about_container">
        <p className="gradient_heading about_container_heading">Our Team</p>
        <span className="about_container_span">
          Roshpit Champions II is the ongoing result of countless hours of
          dedication, sleepless nights, and the relentless pursuit of excellence
          - driven by the unwavering belief that games can be more than just
          entertainment; they can be immersive, emotional journeys that players
          will remember for years to come. We hope you enjoy our game for years
          to come and be a part of our journey in building the most fun game,
          together.
        </span>
        <div className="cards_container">
          <div className="team_card_main">
            <div className="team_card">
              <div className="team_card_front">
                <div className="team_card_front_image">
                  <img src={michael} alt="Michael Yum" />
                </div>
                <span>Lead Producer</span>
                <p>Michael Yum</p>
              </div>
              <div className="team_card_back">
                <div className="team_card_back_bottom_image">
                  <img src={logo} alt="logo" />
                </div>
                <p>Michael Yum</p>
                <span>Lead Producer</span>
                <ul>
                  <li>
                    <p>CEO of PM Studios.</p>
                  </li>
                  <li>
                    <p>25+ Years in GAming Industry.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="team_card_main">
            <div className="team_card">
              <div className="team_card_front">
                <div className="team_card_front_image">
                  <img src={ryan} alt="Ryan Racioppo" />
                </div>
                <span>Head Developer</span>
                <p>Ryan Racioppo</p>
              </div>
              <div className="team_card_back">
                <div className="team_card_back_bottom_image">
                  <img src={logo} alt="" />
                </div>
                <p>Ryan Racioppo</p>
                <span>Head Developer</span>
                <ul>
                  <li>
                    <p>Creator of Roshpit Champions.</p>
                  </li>
                  <li>
                    <p>10+years Coding Experience</p>
                  </li>
                  <li>
                    <p>6 Years in Gaming.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="team_card_main">
            <div className="team_card">
              <div className="team_card_front">
                <div className="team_card_front_image">
                  <img src={david} alt="" />
                </div>
                <span>Producer / Director</span>
                <p>David Whang</p>
              </div>
              <div className="team_card_back">
                <div className="team_card_back_bottom_image">
                  <img src={logo} alt="David Whang" />
                </div>
                <p>David Whang</p>
                <span>Producer / Director</span>
                <ul>
                  <li>
                    <p>15+ Years Project Management</p>
                  </li>
                  <li>
                    <p>4 Years Gaming Development & Logistics</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="team_card_main">
            <div className="team_card">
              <div className="team_card_front">
                <div className="team_card_front_image">
                  <img src={tony} alt="Tony Kornilov" />
                </div>
                <span>Senior Developer</span>
                <p>Tony Kornilov</p>
              </div>
              <div className="team_card_back">
                <div className="team_card_back_bottom_image">
                  <img src={logo} alt="" />
                </div>
                <p>Tony Kornilov</p>
                <span>Senior Developer</span>
                <ul>
                  <li>
                    <p>6 Years Coding Experience.</p>
                  </li>
                  <li>
                    <p>6 Years in Gaming, Modding, Deconstructing.</p>
                  </li>
                  <li>
                    <p>Specialized in Network Programming.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="team_card_main">
            <div className="team_card">
              <div className="team_card_front">
                <div className="team_card_front_image">
                  <img src={matt} alt="Matt Kulmanov" />
                </div>
                <span>Animator / Artist</span>
                <p>Matt Kulmanov</p>
              </div>
              <div className="team_card_back">
                <div className="team_card_back_bottom_image">
                  <img src={logo} alt="" />
                </div>
                <p>Matt Kulmanov</p>
                <span>Animator / Artist</span>
                <ul>
                  <li>
                    <p>10 Years Animation Experience.</p>
                  </li>
                  <li>
                    <p>6 Years in Gaming Design.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="team_card_main">
            <div className="team_card">
              <div className="team_card_front">
                <div className="team_card_front_image">
                  <img src={rong} alt="Rong Choi" />
                </div>
                <span>Concept / Environment Art</span>
                <p>Rong Choi</p>
              </div>

              <div className="team_card_back">
                <div className="team_card_back_bottom_image">
                  <img src={logo} alt="" />
                </div>
                <p>Rong Choi</p>
                <span>Concept / Environment Art</span>

                <ul>
                  <li>
                    <p>12+ Years Environmental Art.</p>
                  </li>
                  <li>
                    <p>10+ Years Concept art Design.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="other_container container">
          <div style={{ height: "max-content" }}>
            <div className="studio_members">
              <div className="studio_members_image">
                <img src={pm} alt="pm studios logo" />
              </div>
              <span>PM Studios Development Team</span>
            </div>
            <div className="outsourced_members">
              <div className="outsourced_members_image">
                <img src={out} alt="outsourced" />
              </div>
              <span>Outsourced Art Team</span>
            </div>
          </div>
          <div style={{ height: "max-content" }}>
            <div className="other_members">
              <span>Other Artists</span>
              <p>Toni Nguyen</p>
              <p>George 'Chark' Romulo</p>
              <p>Anas Riasat</p>
            </div>
            <div className="studio_members_members">
              <span>Web Designer</span>
              <a href="https://www.instagram.com/rahul.jpg/" target="_">
                <p>Rahul Rana</p>
              </a>
            </div>
            <div className="studio_members_members">
              <span>You</span>
              <a href="https://discord.gg/ZPbpdjxTsF" target="_">
                <p>Discord Community</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Team;
