import React, { useEffect, useState } from "react";
import "./styles/heroes.css";
import "./styles/home.css";
import "./styles/mediaqueries.css";
import logo from "../Assets/images/Logo_Square.png";
import { Link } from "react-router-dom";
import axios from "axios";
import Footer from "../components/Footer";

const Heroes = () => {
  const [heroData, setHeroData] = useState(null);
  const [heroes, setHeroes] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Roshpit Champions II | Heroes";
    axios
      .get("https://cotp-game.azurewebsites.net/api/HeroData/GetAllHeroes")
      .then((res) => {
        setHeroData(res?.data);
        setHeroes(res?.data);
      });
  }, []);
  useEffect(() => {
    if (searchValue.length > 0) {
      setHeroes(
        heroData?.filter((item) =>
          item.heroEnum.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      setHeroes(heroData);
    }
  }, [searchValue]);
  return (
    <>
      <div className="heroes">
        <div className="heroes_heading_container container">
          <div className="heroes_heading_image">
            <img src={logo} alt="logo" />
          </div>
          <div className="heroes_heading">
            <p className="gradient_heading">Our Heroes</p>
            <span>
              Roshpit Champions boasts an astounding array of heroes that
              promises an unparalleled gaming experience. With an ever-expanding
              roster of unique and diverse characters, the game offers an
              astonishing variety of playstyles and strategies. Whether you
              prefer spellcasting mages, agile assassins, or stalwart defenders,
              Roshpit Champions provides an extensive lineup to cater to every
              gaming preference.
            </span>
          </div>
        </div>
        <div className="search container">
          <div className="search_wrapper">
            <i class="bi bi-search"></i>
            <input
              type="text"
              placeholder="Search your favourite Hero!"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
        {heroes?.length === 0 ? (
          <div
            style={{
              width: "100%",
              color: "#fff",
              display: "flex",
              justifyContent: "center",
              marginTop: "5rem",
              fontSize: "2rem",
            }}
          >
            <p className="gradient_heading">No Hero Found</p>
          </div>
        ) : (
          <div className="heroes_container container">
            {heroes === null ? (
              <div className="video_overlay1">
                <div className="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
                <p>Loading Heroes...</p>
              </div>
            ) : (
              heroes?.map((item, index) => {
                return (
                  <Link to={`/hero/${item.heroEnum}`}>
                    <div key={index} className="hero">
                      <div className="hero_img">
                        <img src={item.imageUrl} alt={item.heroEnum} />
                        <div className="hero_details">
                          <p className="gradient_heading">{item.heroEnum}</p>
                          <span>{item.desc}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })
            )}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Heroes;
