import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./styles/hero.css";
import parse from "html-react-parser";
import strengthIcon from "../Assets/images/strength.png";
import agilityIcon from "../Assets/images/agility.png";
import intelligenceIcon from "../Assets/images/intelligence.png";
import spiritIcon from "../Assets/images/spirit.png";
import Footer from "../components/Footer";

const Hero = () => {
  const { name } = useParams();
  const [heroDetails, setHeroDetails] = useState(null);
  const [selectedAbilityIndex, setSelectedAbilityIndex] = useState("");
  const [videoVisible, setVideoVisible] = useState(false);

  function formatDescription(description) {
    if (description) {
      let formattedDescription = description?.replace(
        /<#([a-fA-F0-9]{6})>/g,
        '<span style="color: #$1;">'
      );
      formattedDescription = formattedDescription.replace(
        /<\/color>/g,
        "</span>"
      );

      formattedDescription = formattedDescription.replace(/<u>/g, "<u>");
      formattedDescription = formattedDescription.replace(/<\/u>/g, "</u>");

      formattedDescription = formattedDescription.replace(
        /<link=\"{{[^}]*}}\">/g,
        ""
      );
      formattedDescription = formattedDescription.replace(/<\/link>/g, "");
      return formattedDescription;
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      axios
        .get(
          `https://cotp-game.azurewebsites.net/api/HeroData/GetSpecificHero?heroEnum=${name}`
        )
        .then((res) => {
          setHeroDetails(res.data);
        });
    } catch (e) {
      console.log("error", e);
    }
  }, []);
  return (
    <>
      {!heroDetails ? (
        <div
          style={{
            background: "#000",
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          <div className="hero_container">
            <div className="hero_ability">
              <div className="hero_ability_left">
                <h1 className="hero_ability_left_name gradient_heading">
                  {heroDetails?.heroEnum}
                </h1>
                <p className="hero_ability_left_ability">
                  Quam risus volutpat semper risus ullamcorper vitae est ipsum.
                </p>
                <p className="hero_ability_left_desc">
                  Lorem ipsum dolor sit amet consectetur. Quam risus volutpat
                  semper risus ullamcorper vitae est ipsum. Morbi sagittis
                  sagittis facilisis elementum sapien senectus netus sed sed.
                </p>
                <div className="hero_ability_left_abilities_details">
                  <div className="hero_ability_left_abilities_details_field">
                    <img src={strengthIcon} alt="" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <p>Strength</p>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        {`${heroDetails?.strength}`}
                        <span
                          style={{
                            color: "#2db469",
                            fontSize: "1.8rem",
                            transform: "skew(0deg)",
                          }}
                        >{`+${heroDetails?.perLevelStrength}`}</span>
                      </span>
                    </div>
                  </div>
                  <div className="hero_ability_left_abilities_details_field">
                    <img src={agilityIcon} alt="" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <p>Agility</p>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        {`${heroDetails?.agility}`}
                        <span
                          style={{
                            color: "#2db469",
                            fontSize: "1.8rem",
                            transform: "skew(0deg)",
                          }}
                        >{`+${heroDetails?.perLevelAgility}`}</span>
                      </span>
                    </div>
                  </div>
                  <div className="hero_ability_left_abilities_details_field">
                    <img src={intelligenceIcon} alt="" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <p>Intelligence</p>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        {`${heroDetails?.intelligence}`}
                        <span
                          style={{
                            color: "#2db469",
                            fontSize: "1.8rem",
                            transform: "skew(0deg)",
                          }}
                        >{`+${heroDetails?.perLevelIntelligence}`}</span>
                      </span>
                    </div>
                  </div>

                  <div className="hero_ability_left_abilities_details_field">
                    <img src={spiritIcon} alt="" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p>Spirit</p>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        {`${heroDetails?.spirit}`}
                        <span
                          style={{
                            color: "#2db469",
                            fontSize: "1.8rem",
                            transform: "skew(0deg)",
                          }}
                        >{`+${heroDetails?.perLevelSpirit}`}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero_ability_right">
                <video autoPlay muted loop id="myVideo">
                  <source
                    src={heroDetails?.heroPreviewVideo}
                    type="video/webm"
                  />
                </video>
              </div>
            </div>
            <div className="abilities_grid container">
              <p className="gradient_heading abilities_grid_heading">
                Abilities
              </p>
              <div className="abilities_grid_container">
                {heroDetails?.abilityData
                  ?.filter((item) => item?.runeData.length > 0)
                  .map((item, index) => {
                    return (
                      <div
                        className="abilities_grid_item"
                        onMouseEnter={() => {
                          setSelectedAbilityIndex(item?.abilityClassName);
                        }}
                        onClick={() => setVideoVisible(true)}
                        style={{
                          border:
                            selectedAbilityIndex === item?.abilityClassName
                              ? "3px solid #fff"
                              : "3px solid transparent",
                          filter:
                            selectedAbilityIndex == item?.abilityClassName
                              ? "grayscale(0%)"
                              : "grayscale(60%)",
                        }}
                      >
                        <div className="abilities_grid_item_details">
                          <div
                            className="abilities_grid_item_details_video"
                            style={{
                              backgroundImage: `url(${item?.videoPreview})`,
                            }}
                          >
                            <video autoPlay muted loop id="myVideo">
                              <source src={item?.video} type="video/mp4" />
                            </video>
                          </div>
                          <div className="abilities_grid_item_details_details">
                            <p className="abilities_grid_item_details_details_name">
                              {item.name}
                            </p>
                            <p className="abilities_grid_item_details_details_desc">
                              {item?.description?.length > 0 &&
                                parse(formatDescription(item?.description))}
                            </p>
                          </div>
                        </div>
                        <img
                          className="abilities_grid_item_img"
                          src={item.imageUrl}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="abilities_grid container">
              <p className="gradient_heading abilities_grid_heading">Runes</p>
              <div style={{ display: "flex", gap: "10px" }}>
                {heroDetails?.abilityData
                  ?.filter((item) => item?.runeData.length > 0)
                  .map((item, index) => {
                    return (
                      <div className="abilities_grid_container_horizontal">
                        {item?.runeData?.map((item, index) => {
                          return (
                            <div className="abilities_grid_item">
                              <div className="abilities_grid_item_details">
                                <div className="abilities_grid_item_details_details">
                                  <p className="abilities_grid_item_details_details_name">
                                    {item.name}
                                  </p>
                                  <p className="abilities_grid_item_details_details_desc">
                                    {item?.description?.length > 0 &&
                                      parse(
                                        formatDescription(item?.description)
                                      )}
                                  </p>
                                </div>
                              </div>
                              <img
                                className="abilities_grid_item_img"
                                src={item.imageUrl}
                              />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="abilities_grid container">
              <p className="gradient_heading abilities_grid_heading">
                Sub Abilities
              </p>
              <div className="abilities_grid_container">
                {heroDetails?.abilityData
                  ?.filter((item) => item?.runeData.length == 0)
                  .map((item, index) => {
                    return (
                      index > 0 && (
                        <div
                          className="abilities_grid_item"
                          onMouseEnter={() => {
                            setSelectedAbilityIndex(item?.abilityClassName);
                          }}
                          onClick={() => setVideoVisible(true)}
                          style={{
                            border:
                              selectedAbilityIndex === item?.abilityClassName
                                ? "3px solid #fff"
                                : "3px solid transparent",
                            filter:
                              selectedAbilityIndex === item?.abilityClassName
                                ? "grayscale(0%)"
                                : "grayscale(60%)",
                          }}
                        >
                          <div className="abilities_grid_item_details">
                            <div
                              className="abilities_grid_item_details_video"
                              style={{
                                backgroundImage: `url(${item?.videoPreview})`,
                              }}
                            >
                              <video autoPlay muted loop id="myVideo">
                                <source src={item?.video} type="video/mp4" />
                              </video>
                            </div>
                            <div className="abilities_grid_item_details_details">
                              <p className="abilities_grid_item_details_details_name">
                                {item.name}
                              </p>
                              <p className="abilities_grid_item_details_details_desc">
                                {item?.description?.length > 0 &&
                                  parse(formatDescription(item?.description))}
                              </p>
                            </div>
                          </div>
                          <img
                            className="abilities_grid_item_img"
                            src={item.imageUrl}
                          />
                        </div>
                      )
                    );
                  })}
              </div>
            </div>

            {videoVisible && (
              <div className="video_container container">
                <div
                  className="video_container_video"
                  style={{
                    backgroundImage: `url(${
                      heroDetails?.abilityData.find(
                        (item) => item.abilityClassName === selectedAbilityIndex
                      )?.videoPreview
                    })`,
                    backgroundSize: "cover",
                  }}
                >
                  <video
                    autoPlay
                    muted
                    loop
                    id="myVideo"
                    key={
                      heroDetails?.abilityData.find(
                        (item) => item.abilityClassName === selectedAbilityIndex
                      )?.video
                    }
                  >
                    <source
                      src={
                        heroDetails?.abilityData.find(
                          (item) =>
                            item.abilityClassName === selectedAbilityIndex
                        )?.video
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className="video_container_details">
                  <p>
                    {
                      heroDetails?.abilityData.find(
                        (item) => item.abilityClassName === selectedAbilityIndex
                      )?.name
                    }
                  </p>
                  <span>
                    {heroDetails?.abilityData.find(
                      (item) => item.abilityClassName === selectedAbilityIndex
                    )?.description?.length > 0 &&
                      parse(
                        formatDescription(
                          heroDetails?.abilityData.find(
                            (item) =>
                              item.abilityClassName === selectedAbilityIndex
                          )?.description
                        )
                      )}
                  </span>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Hero;
