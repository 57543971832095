import React from "react";
import "../pages/styles/home.css";
import logo from "../Assets/images/pmchampionslogo.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="social_icons_footer">
        <div className="container1">
          <p>Our Social Media Handles.</p>
          <div className="social_icons_icons">
            <a href="https://www.twitter.com/roshpit" target="_">
              <i className="bi bi-twitter"></i>
            </a>
            <a href="https://www.facebook.com/roshpitchampions" target="_">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="https://www.instagram.com/roshpitchampions" target="_">
              <i className="bi bi-instagram"></i>
            </a>
            <a href="https://discord.gg/ZPbpdjxTsF" target="_">
              <i className="bi bi-discord"></i>
            </a>
            <a href="https://www.twitch.tv/chalkybrush" target="_">
              <i className="bi bi-twitch"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="copyrights">
        <p>&copy; 2024 PM Champions, LLC - Roshpit Champions II</p>
      </div>
    </div>
  );
};

export default Footer;
