import React, { useState, useEffect } from "react";
import "./styles/home.css";
import "./styles/mediaqueries.css";
import logo from "../Assets/images/Logo.png";
import video from "../Assets/images/rpc2-trailer.mp4";
import character1 from "../Assets/images/characters01.png";
import character2 from "../Assets/images/characters02.png";
import videoFrame from "../Assets/images/video_frame.png";
import comingSoonText from "../Assets/images/coming-soon.png";
import Footer from "../components/Footer";

const Home = () => {
  const [showCharacter1, setShowCharacter1] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowCharacter1((prev) => !prev);
    }, 10000); // Change image every 10 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container-rpc">
      <div className="content-rpc">
        <div className="left-side">
          <img src={logo} alt="Roshpit Champions II" className="logo" />
          <div className="video-container">
            <video controls>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={videoFrame} alt="Video Frame" className="video-frame" />
          </div>
          <img className="early-access-text" alt="Coming Soon" src={comingSoonText}/>
          <div className="buttons">
            <a href="https://discord.com" className="button discord">
              Discord
            </a>
            <a href="https://store.steampowered.com/app/2783670/Roshpit_Champions_2/?beta=1" className="button steam">
              Steam
            </a>
          </div>
        </div>
        <div className="right-side">
          <img
            src={character1}
            alt="Character 1"
            className={`character-img ${showCharacter1 ? "fade-in" : "fade-out"}`}
          />
          <img
            src={character2}
            alt="Character 2"
            className={`character-img ${showCharacter1 ? "fade-out" : "fade-in"}`}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
