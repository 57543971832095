import React, { useEffect, useState } from "react";
import "../pages/styles/changelog.css";
import "../pages/styles/home.css";
import "./styles/mediaqueries.css";
import logo from "../Assets/images/Logo_Square_mini.png";

import Footer from "../components/Footer";
import axios from "axios";
import moment from "moment/moment";
import parse from "html-react-parser";

const Changelog = () => {
  const [changelogData, setChangelogData] = useState(null);

  function formatDescription(description) {
    if (description) {
      let formattedDescription = description?.replace(
        /<#([a-fA-F0-9]{6})>/g,
        '<span style="color: #$1;">'
      );
      formattedDescription = formattedDescription.replace(
        /<\/color>/g,
        "</span>"
      );

      formattedDescription = formattedDescription.replace(/<u>/g, "<u>");
      formattedDescription = formattedDescription.replace(/<\/u>/g, "</u>");
      formattedDescription = formattedDescription.replace(/\[b]/g, "<b>");
      formattedDescription = formattedDescription.replace(/\[\/b]/g, "</b>");

      formattedDescription = formattedDescription.replace(
        /<link=\"{{[^}]*}}\">/g,
        ""
      );
      formattedDescription = formattedDescription.replace(/<\/link>/g, "");
      return formattedDescription;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Roshpit Champions II | Changelog";
    try {
      axios
        .get(`https://cotp-game.azurewebsites.net/api/SiteGoogleSheet`)
        .then((res) => {
          setChangelogData(res.data);
        });
    } catch (e) {
      console.log("error", e);
    }
  }, []);
  return (
    <>
      <div className="changelog_container">
        <p className="gradient_heading changelog_container_heading">
          Changelogs
        </p>
        {changelogData === null ? (
          <div className="video_overlay1">
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
            <p>Loading Changelogs...</p>
          </div>
        ) : (
          <div className="changelogs container">
            {changelogData?.length === 0 ? (
              <p>No Data Found</p>
            ) : (
              changelogData?.map((item, index) => {
                return (
                  <div className="changelog" key={index}>
                    <div className="changelog_title">
                      <p>{item.tittle}</p>
                      <span>
                        {moment(item.dateTimeUtc).format("MM/DD/YYYY")}
                      </span>
                    </div>
                    <div className="changelog_desc">
                      <p>
                        {item?.changes?.length > 0 &&
                          parse(formatDescription(item?.changes))}
                      </p>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Changelog;
