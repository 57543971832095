import React, { useEffect } from "react";
import "./styles/contact.css";
import "./styles/mediaqueries.css";
import Footer from "../components/Footer";
const Contact = () => {
  useEffect(() => {
    document.title = "Roshpit Champions II | Contact";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contact_us">
      <div className="contact_us_container">
        <p className="gradient_heading contact_us_container_heading">
          Contact Us
        </p>
        <span className="contact_us_container_span">
          If you have any questions or comments about Roshpit Champions, feel
          free to contact us.
        </span>
        <div className="contact_us_links">
          <ul>
            <li>
              <a href="mailto://dwhang@pm-studios.com">
                <i className="bi bi-envelope-fill"></i>
                <p>Mail</p>
              </a>
            </li>
            <li>
              <a href="https://www.twitter.com/roshpit" target="_">
                <i className="bi bi-twitter"></i>
                <p>twitter</p>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/roshpitchampions" target="_">
                <i className="bi bi-instagram"></i>
                <p>Instagram</p>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/roshpitchampions" target="_">
                <i className="bi bi-facebook"></i>
                <p>facebook</p>
              </a>
            </li>
            <li>
              <a href="https://discord.gg/ZPbpdjxTsF" target="_">
                <i className="bi bi-discord"></i>
                <p>Discord</p>
              </a>
            </li>

            <li>
              <a href="https://www.twitch.tv/chalkybrush" target="_">
                <i className="bi bi-twitch"></i>
                <p>twitch</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
