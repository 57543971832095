import React, { useEffect } from "react";
import "../pages/styles/about.css";
import "./styles/mediaqueries.css";
import gameplay3 from "../Assets/images/gameplay_3.png";
import Footer from "../components/Footer";

const About = () => {
  useEffect(() => {
    document.title = "Roshpit Champions II | About";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="about_container">
        <p className="gradient_heading about_container_heading">
          {" "}
          The Roshpit Mission
        </p>
        <span className="about_container_span">
          To become the most fun game you will ever play.
        </span>
        <div className="about_image container">
          <img src={gameplay3} alt="gameplay" />
        </div>
        <div className="about_text container">
          <p className="gradient_heading about_text_heading">Our Story</p>
          <span className="about_text_span">
            Roshpit Champions was born from an idea after playing hours and
            hours of DOTA2. Ryan "ChalkyBrush" Raciopppo dabbled with the map
            editor and thought that it would be awesome to create an action RPG
            adventure.<br></br>
            <br></br>
            He started work on the first iteration in 2016 and within two years,
            had over 100,000 user accounts. A year later, with him releasing his
            version 3.2 update, there were over 230,000 user accounts created.
            In 2020, with the version 4.0 update, ChalkyBrush partnered with
            Valve and became the first mod to incorporate a Battle Pass
            functionality - establishing the game as a recognized mod in the
            community. By 2020, no doubt due to the relationship with Valve, the
            game skyrocketed in popularity and had over 2 million subscribers.
            <br></br>
            <br></br>
            The game caught the eye of the Producer, David "Oppa" Whang, as he
            was looking to play some mods after getting pummeled much too often
            in DOTA2. Within the first hour, Oppa knew that this mod was
            special. As he instantly became hooked, he was astounded that
            someone could take elements of Diablo 2, DOTA2, and some of his
            other favorite games and combine them to create such an awesome mod.
            He reached out to several people and Michael "Bnis" Yum, CEO of PM
            Studios, bought in immediately. As someone working in the gaming
            industry for over 20 years, and having made many hit games, Michael
            knew ChalkyBrush's vision was sound. From there, PM Champions was
            born.<br></br>
            <br></br>
            The PM Champions Team has been working on this project since the end
            of 2020 and we are super excited to release this game soon. We would
            love for everyone who enjoyed Roshpit Champions before to come and
            try this new and improved game with us. As we consider ourselves a
            community based game, we would love to grow and shape the future of
            the game with you. Please considering becoming a part of our Discord
            community.
          </span>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
