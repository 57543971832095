import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Gameplay from "./pages/Gameplay";
import About from "./pages/About";
import Media from "./pages/Media";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import Heroes from "./pages/Heroes";
import Hero from "./pages/Hero";

import Changelog from "./pages/Changelog";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gameplay" element={<Gameplay />} />
          <Route path="/about" element={<About />} />
          <Route path="/media" element={<Media />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/heroes" element={<Heroes />} />
          <Route path="/changelog" element={<Changelog />} />
          <Route path="/hero/:name" element={<Hero />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
