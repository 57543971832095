import React, { useEffect } from "react";
import "../pages/styles/media.css";
import "../pages/styles/home.css";
import "./styles/mediaqueries.css";
import news1 from "../Assets/images/news1.png";
import news2 from "../Assets/images/news2.jpg";
import news3 from "../Assets/images/news3.jpg";
import news4 from "../Assets/images/news4.jpg";
import Footer from "../components/Footer";

const Media = () => {
  useEffect(() => {
    document.title = "Roshpit Champions II | Media";
  }, []);
  return (
    <>
      <div className="media_container">
        <p className="media_container_heading gradient_heading">Media News</p>
        <span className="media_container_span">
          Lorem ipsum dolor sit amet consectetur. Quam risus volutpat semper
          risus ullamcorper vitae est ipsum. Morbi sagittis sagittis facilisis
          elementum sapien senectus netus sed sed.
        </span>
        <div className="featured_articles container">
          <p className="featured_articles_heading">Featured Articles</p>
          <div className="featured_articles_container">
            <div className="featured_article_gradient_outline">
              <div className="featured_article">
                <div className="featured_article_content">
                  <h2 className="featured_article_content_heading">
                    News Heading
                  </h2>
                  <p className="featured_article_content_paragraph">
                    Lorem ipsum dolor sit amet consectetur. Odio auctor nibh
                    pharetra nam augue maecenas non semper. Viverra pretium
                    volutpat quis faucibus sit dignissim ornare sem. Auctor
                    rhoncus egestas hendrerit bibendum nam elit pretium. Lorem
                    orci facilisi lobortis maecenas eu molestie suspendisse
                    maecenas. Fringilla interdum maecenas nec aliquet enim
                    porttitor nunc ullamcorper. Elementum molestie dolor
                    senectus dui volutpat odio diam dolor quam.
                  </p>
                  <a href="">Read full Article &#x2197;</a>
                </div>
                <div className="featured_article_image">
                  <img src={news3} alt="article" />
                </div>
              </div>
            </div>
            <div className="featured_article_gradient_outline">
              <div className="featured_article">
                <div className="featured_article_content">
                  <h2 className="featured_article_content_heading">
                    News Heading
                  </h2>
                  <p className="featured_article_content_paragraph">
                    Lorem ipsum dolor sit amet consectetur. Odio auctor nibh
                    pharetra nam augue maecenas non semper. Viverra pretium
                    volutpat quis faucibus sit dignissim ornare sem. Auctor
                    rhoncus egestas hendrerit bibendum nam elit pretium. Lorem
                    orci facilisi lobortis maecenas eu molestie suspendisse
                    maecenas. Fringilla interdum maecenas nec aliquet enim
                    porttitor nunc ullamcorper. Elementum molestie dolor
                    senectus dui volutpat odio diam dolor quam. Ipsum mi
                    hendrerit nunc odio aliquet massa.
                  </p>
                  <a href="">Read full Article &#x2197;</a>
                </div>
                <div className="featured_article_image">
                  <img src={news4} alt="article" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="all_articles container"
          style={{ marginBottom: "4rem" }}
        >
          <p className="all_articles_heading">All Articles</p>
          <div className="all_articles_container">
            <div className="article_gradient_outline">
              <div className="article">
                <div className="article_content">
                  <h2 className="article_content_heading">News Heading</h2>
                  <p className="article_content_paragraph">
                    orem ipsum dolor sit amet consectetur. Odio auctor nibh
                    pharetra nam augue maecenas non semper.
                  </p>
                  <a href="">Read full Article &#x2197;</a>
                </div>
                <div className="article_image">
                  <img src={news1} alt="article" />
                </div>
              </div>
            </div>
            <div className="article_gradient_outline">
              <div className="article">
                <div className="article_content">
                  <h2 className="article_content_heading">News Heading</h2>
                  <p className="article_content_paragraph">
                    orem ipsum dolor sit amet consectetur. Odio auctor nibh
                    pharetra nam augue maecenas non semper.
                  </p>
                  <a href="">Read full Article &#x2197;</a>
                </div>
                <div className="article_image">
                  <img src={news2} alt="article" />
                </div>
              </div>
            </div>
            <div className="article_gradient_outline">
              <div className="article">
                <div className="article_content">
                  <h2 className="article_content_heading">News Heading</h2>
                  <p className="article_content_paragraph">
                    orem ipsum dolor sit amet consectetur. Odio auctor nibh
                    pharetra nam augue maecenas non semper.
                  </p>
                  <a href="">Read full Article &#x2197;</a>
                </div>
                <div className="article_image">
                  <img src={news3} alt="article" />
                </div>
              </div>
            </div>

            <div className="article_gradient_outline">
              <div className="article">
                <div className="article_content">
                  <h2 className="article_content_heading">News Heading</h2>

                  <p className="article_content_paragraph">
                    orem ipsum dolor sit amet consectetur. Odio auctor nibh
                    pharetra nam augue maecenas non semper. Viverra pretium
                    volutpat quis faucibus
                  </p>

                  <a href="">Read full Article &#x2197;</a>
                </div>
                <div className="article_image">
                  <img src={news4} alt="article" />
                </div>
              </div>
            </div>
            <div className="article_gradient_outline">
              <div className="article">
                <div className="article_content">
                  <h2 className="article_content_heading">News Heading</h2>
                  <p className="article_content_paragraph">
                    orem ipsum dolor sit amet consectetur. Odio auctor nibh
                    pharetra nam augue maecenas non semper. Viverra pretium
                    volutpat quis faucibus sit dignissim ornare sem.
                  </p>
                  <a href="">Read full Article &#x2197;</a>
                </div>
                <div className="article_image">
                  <img src={news3} alt="article" />
                </div>
              </div>
            </div>
            <div className="article_gradient_outline">
              <div className="article">
                <div className="article_content">
                  <h2 className="article_content_heading">News Heading</h2>
                  <p className="article_content_paragraph">
                    orem ipsum dolor sit amet consectetur. Odio auctor nibh
                    pharetra nam augue maecenas non semper. Viverra pretium
                    volutpat quis faucibus sit dignissim ornare sem.
                  </p>
                  <a href="">Read full Article &#x2197;</a>
                </div>
                <div className="article_image">
                  <img src={news1} alt="article" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Media;
