import React, { useEffect, useState } from "react";
import "../pages/styles/gameplay.css";
import "./styles/mediaqueries.css";
import runeCrystal from "../Assets/images/runecrystal.png";
import blueGem from "../Assets/images/blue_gem.png";
import greenGem from "../Assets/images/green_gem.png";
import gameplay1 from "../Assets/images/gameplay1.jpg";
import gameplay2 from "../Assets/images/gameplay2.jpg";
import gameplay3 from "../Assets/images/gameplay3.jpg";
import gameplay4 from "../Assets/images/gameplay4.jpg";
import gameplay5 from "../Assets/images/gameplay5.jpg";
import carouselButton1 from "../Assets/images/icon-1.png";
import carouselButton2 from "../Assets/images/icon-2.png";
import carouselButton3 from "../Assets/images/icon-3.png";
import marketplace from "../Assets/images/marketplace.jpg";
import enemylevels from "../Assets/images/enemylevels.jpg";
import customization from "../Assets/images/customization.jpg";
import zodiac1 from "../Assets/images/zodiac1.jpg";
import zodiac2 from "../Assets/images/zodiac2.jpg";
import itemization from "../Assets/images/itemization.jpg";

import Footer from "../components/Footer";

const Gameplay = () => {
  const [video1Loading, setVideo1Loading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  // const [carouselIsLooping, setCarouselIsLooping] = useState(false);

  const carouselData = [
    {
      icon: carouselButton1,
      title: "Heroes",
      image: gameplay1,
      desc: `With 17 heroes returning from launch (and with your support, no plans to stop adding more) Roshpit Champions II separates itself from all other action RPGs with the sheer number of heroes in the game.
      The original Roshpit had 30 characters which we have a blueprint for and will bring most back along with working with our wonderful Discord community to create all new heroes.
      Our goal is to expand infinitely as long as you enjoy the game and become the most fun game you will ever play.`,
    },
    {
      icon: carouselButton2,
      title: "Game World",
      image: gameplay2,
      desc: `Set in the Tanari Jungle divided into 19 zones, our heroes cautiously navigate each environment running into some familiar foes and also, new enemies.
      Heavily influenced by DOTA2, Diablo 2, World of Warcraft, the Mario Bros series, the Zelda series, and many more games, the Roshpit World is our ambitious attempt of the perfect blend of gameplay styles and elements.
      Breaking away from the traditional 2D action RPG model, we hope that you enjoy the platforming, puzzle solving, heavily customizable, free roaming 3D action RPG world.`,
    },
    {
      icon: carouselButton3,
      title: "Equipment/Loot",
      image: gameplay3,
      desc: `No action RPG is complete without awesome equipment and we've seen superb AAA games fail from the lack of items (shots fired).
      As heavy gamers ourselves, we know that great loot is essential in extending gameplay and making an action RPG 'fun'.
      Along with the typical items that boost stats and upgrade skills, we have a revolutionary new system of leeching other hero skills.
      We don't want to share too much on this until you play the game. We are definitely proud of this unique model.`,
    },
    {
      icon: carouselButton1,
      title: "Multiplayer",
      image: gameplay4,
      desc: `Yes, we have multiplayer.  (Waiting for applause to subside)
      While it might seem like a natural thing to have, it is one of our biggest challenges for our small development team to incorporate properly.
      As we navigate the hopefully big population explosion of our game, please bear with us as we will continue to try to make your gaming experience awesome.
      But yes, we have multiplayer and it is working and it is definitely awesome so far. 🙂`,
    },
    {
      icon: carouselButton2,
      title: "Bosses",
      image: gameplay5,
      desc: `Cool bosses are a staple and we don't shy away from making challenging and ridiculously strong ones.
      We know how fun a good boss fight can be and every zone has a boss that hopefully brings a ton of excitement - and good loot.
      Try defeating it solo or bring friends along to help you conquer the toughest of foes.`,
    },
  ];
  useEffect(() => {
    document.title = "Roshpit Champions II | Gameplay";
    window.scrollTo(0, 0);
  }, []);
  // useEffect(() => {
  //   if (activeIndex <= carouselData.length - 1) {
  //     const id = setTimeout(() => {
  //       setActiveIndex((activeIndex) => activeIndex + 1);
  //       return () => clearTimeout(id);
  //     }, 2000);
  //   } else {
  //     setActiveIndex(0);
  //   }
  // }, [activeIndex]);

  return (
    <>
      <div className="gameplay_section">
        <div className="gameplay_section_video">
          {/* <div className="gameplay_section_video_text">
            <p className="gameplay_section_video_text_heading gradient_heading">
              Gameplay
            </p>
            <span className="gameplay_section_video_text_span">
              Lorem ipsum dolor sit amet consectetur. Quam risus volutpat semper
              risus ullamcorper vitae est ipsum. Morbi sagittis sagittis
              facilisis elementum sapien senectus netus sed sed.
            </span>
          </div> */}
          <video autoPlay muted loop id="myVideo">
            <source
              src="https://rpc2-game.s3.us-west-1.amazonaws.com/RpcData/Trailers/Trailer_1.webm"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="bottom_carousel">
          <div className="bottom_carousel_heading">
            <p className="gradient_heading">Enter the Roshpit</p>
            <span>
              The Champions, once again, hear the howls of the Roshpit. Blaze
              your way through the Tanari Jungles with one of the many heroes
              from the original game. With whole new looks, familiar names and
              items, along with brand new gameplay elements, we hope that you
              enjoy the game we've spent many years creating and will continue
              to improve upon.
            </span>
          </div>
          <div className="bottom_carousel_buttons">
            {carouselData?.map((item, index) => {
              return (
                <div className="bottom_carousel_buttons_item">
                  <button
                    style={{
                      boxShadow:
                        activeIndex === index ? "0px 0px 20px #b79155" : "none",
                    }}
                    onClick={() => setActiveIndex(index)}
                  >
                    <img src={item.icon} alt={item.title} />
                  </button>{" "}
                  <p>{item.title}</p>
                </div>
              );
            })}
          </div>
          <div className="bottom_carousel_carousel">
            <div className="bottom_carousel_carousel_prev">
              <button
                onClick={() => {
                  if (activeIndex > 0) {
                    setActiveIndex(activeIndex - 1);
                  } else if (activeIndex === 0) {
                    setActiveIndex(carouselData?.length - 1);
                  }
                }}
              >
                <i class="bi bi-caret-left-fill"></i>
              </button>
            </div>
            <div className="bottom_carousel_carousel_image">
              <img
                src={carouselData[activeIndex]?.image}
                alt={carouselData[activeIndex]?.title}
              />
            </div>
            <div className="bottom_carousel_carousel_next">
              <button
                onClick={() => {
                  if (activeIndex < carouselData?.length - 1) {
                    setActiveIndex(activeIndex + 1);
                  } else if (activeIndex == carouselData?.length - 1) {
                    setActiveIndex(0);
                  }
                }}
              >
                <i class="bi bi-caret-right-fill"></i>
              </button>
            </div>
          </div>
          <div className="bottom_carousel_details">
            <p className="gradient_heading">
              {carouselData[activeIndex]?.title}
            </p>
            <span>{carouselData[activeIndex]?.desc}</span>
          </div>
          <div className="bottom_carousel_navigation_buttons">
            {[...Array(5)].map((item, index) => (
              <button
                style={{
                  boxShadow:
                    activeIndex === index ? "0px 0px 10px #b79155" : "none",
                }}
                onClick={() => setActiveIndex(index)}
              ></button>
            ))}
          </div>
        </div>
        <div className="gameplay_section_container">
          <div className="gameplay_section_container_wrapper container">
            <div className="gameplay_section_container_wrapper_currency">
              {/* <div className="gameplay_section_container_wrapper_currency_image">
                <img src={coin} alt="coin" />
              </div> */}
              <div className="gameplay_section_container_wrapper_currency_text">
                <p className="gameplay_section_container_wrapper_currency_text_heading gradient_heading">
                  Game Currency
                </p>
                <span className="gameplay_section_container_wrapper_currency_text_span">
                  Similar to the first game, there are 3 main forms of currency
                  in Roshpit Champions II. Fight through mobs, destroy world
                  bosses, participate in challenges, and fulfill quests to
                  gather the resources of Tanari Jungle.
                </span>
              </div>
            </div>
            <div className="currency_container container">
              <div className="currency_card currency_card2">
                <div className="currency_card_image">
                  <img src={blueGem} alt="blueGem" />
                </div>
                <p className="currency_card_name">Mithril Shards</p>
                <p className="currency_card_type">
                  Rarity Level :<span> Common</span>
                </p>
                <p className="currency_card_desc currency_card_desc2">
                  Considered the primary currency. It is used to reroll
                  equipment stats and to purchase common items. Acquired by
                  defeating main bosses.
                </p>
              </div>
              <div className="currency_card currency_card1">
                <div className="currency_card_image">
                  <img src={runeCrystal} alt="runeCrystal" />
                </div>
                <p className="currency_card_name">Arcane Crystal</p>
                <p className="currency_card_type">
                  Rarity Level :<span> Rare</span>
                </p>
                <p className="currency_card_desc currency_card_desc1">
                  Used to craft glyphs and purchase talent respects. Acquired by
                  defeating Elite mobs.
                </p>
              </div>

              <div className="currency_card currency_card3">
                <div className="currency_card_image">
                  <img src={greenGem} alt="greenGem" />
                </div>
                <p className="currency_card_name">Prismatic Gemstones</p>
                <p className="currency_card_type">
                  Rarity Level :<span> Super Rare</span>
                </p>
                <p className="currency_card_desc currency_card_desc3">
                  Used to craft gems which can be inserted into gear’s empty
                  sockets. Acquired by completing hourly challenges and other
                  events.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="gameplay_video1">
          {video1Loading && (
            <div className="video_overlay1">
              <div className="lds-ripple">
                <div></div>
                <div></div>
              </div>
              <p>Loading video...</p>
            </div>
          )}
          <video
            autoPlay
            muted
            loop
            onLoadedData={() => {
              setVideo1Loading(false);
            }}
          >
            <source
              src="https://rpc2-game.s3.us-west-1.amazonaws.com/RpcData/Trailers/Trailer_2.webm"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="gameplay_section_container_wrapper container">
          <div
            className="gameplay_section_container_wrapper_currency"
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <div className="gameplay_section_container_wrapper_currency_image">
                <img src={coin} alt="coin" />
              </div> */}
            <div className="gameplay_section_container_wrapper_currency_text">
              <p className="gameplay_section_container_wrapper_currency_text_heading gradient_heading">
                Marketplace
              </p>
              <span className="gameplay_section_container_wrapper_currency_text_span">
                After much deliberation on how to best implement a marketplace,
                we thought the best way would be to have it in-game. This way,
                you can go to town, put an item up for trade/sale, and then go
                straight to fighting baddies again. To use the Marketplace,
                please go to town and speak with Fable, the Consignment
                Auctioneer.
              </span>
              <img src={marketplace} alt="" />
              <span className="gameplay_section_container_wrapper_currency_text_span">
                You will have the choice of 'x,y,z'. Upon selecting X, you will
                be able to list the item you want for trade. Once confirmed,
                this item will now be visible to all players and will allow it
                to be bought. The next time you see Fable, he will give you your
                earnings, minus his 10% cut (lower for Battle Pass).
                <br></br>
                In the future, we may implement a way to trade outside of the
                game as well
              </span>
            </div>
            <div className="gameplay_section_container_wrapper_currency_text">
              <p className="gameplay_section_container_wrapper_currency_text_heading gradient_heading">
                Zodiac Board
              </p>
              <span className="gameplay_section_container_wrapper_currency_text_span">
                Similar to skill trees and paragon boards, Roshpit II introduces
                the Zodiac. This Tri-force looking thing may look daunting at
                first - something we've tried to avoid to keep our game simple.
                However, we felt that adding this brought substantial substance
                to the endgame and brought much needed earlygains for certain
                heroes. You can gain Zodiac points by leveling up, completing
                quests, and competing in world events. While we have to put
                maximum point limits, we wanted you to be able to get the points
                playing the style you want to. To also make it easier for
                beginners, we have an auto-assign button that will auto-assign
                points for you.
              </span>
              <img src={zodiac1} alt="" />
              <span className="gameplay_section_container_wrapper_currency_text_span">
                While we can't compete on graphics with the AAA games, we think
                our Zodiac board looks the best. Once completed, you will see
                the board create a beautiful constellation for you to look at.
                =)
              </span>
              <img src={zodiac2} alt="" />
            </div>
            <div className="gameplay_section_container_wrapper_currency_text">
              <p className="gameplay_section_container_wrapper_currency_text_heading gradient_heading">
                Itemization
              </p>
              <span className="gameplay_section_container_wrapper_currency_text_span">
                There are 6? classes of items (Normal, Magic, Rare, Legendary,
                Mythic, Arcana) Normal items, like other action-RPGs, are used
                in the early game and are promptly trashed when a colored item
                drops. However, because we care about the environment, we are
                introducing the Recycling feature. If you bring your normal or
                magic items to the Dragon Blacksmith, he will recycle them for
                you and provide you with a green voucher (get it?) With 100
                green vouchers, you can trade it for a mystery box. The mystery
                box will have a 60% chance at a Rare, 25% chance at a Legendary,
                a 5% chance at an Arcana, and a 10% chance at a Mythic. You can
                also trade the mystery boxes themselves.
              </span>
              <img src={itemization} alt="" />
              <span className="gameplay_section_container_wrapper_currency_text_span">
                Each class of item will have a different number of Attribute
                slots. Most attributes take one spot but certain ones take two.
                <br></br>
                Arcana Items are extremely rare and contained pre-determined
                skills with random rolls. These are game-changing as they
                introduce new skills or enhancements that will change your
                character. You can build around these Arcanas to make totally
                different builds.
              </span>
            </div>
            <div className="gameplay_section_container_wrapper_currency_text">
              <p className="gameplay_section_container_wrapper_currency_text_heading gradient_heading">
                Customization
              </p>
              <span className="gameplay_section_container_wrapper_currency_text_span">
                You are probably wondering 'That's great and all, but to find
                one of those perfect skill-items must take years of grinding...'
                While grinding is one of the important aspects of action-RPGs,
                it should not be the only reason you play the game. This is why
                our Dragon Blacksmith can help you reroll your item
                attributes... on... every... single... attribute.
              </span>
              <img src={customization} alt="" />
              <span className="gameplay_section_container_wrapper_currency_text_span">
                The way it works is that you can 'lock' an attribute that you
                like. You can 'lock' as many as you want. The more that you
                'lock', the harder Dragon Blacksmith has to work - hence his
                extra charges. For example, if you have an item with 8 slots and
                you have 6 awesome attributes but want to replace two of them,
                you don't have to spend hours grinding finding the 'perfect'
                item. Instead, you will lock 6 of the slots and pay the Dragon
                Blacksmith to reroll only 2 of the slots for you.
                <br></br>
                Yes, the cost of this is pretty high for locking 6 slots but we
                think it's much easier than finding a perfect item with 8
                awesome rolls
              </span>
            </div>
            <div className="gameplay_section_container_wrapper_currency_text">
              <p className="gameplay_section_container_wrapper_currency_text_heading gradient_heading">
                Enemy Levels
              </p>
              <span className="gameplay_section_container_wrapper_currency_text_span">
                By now, I hope you can understand the team's appreciation for
                action-RPGs. We played a lot of them and enjoyed many aspects of
                each. We tried to take the best from each and make the perfect
                mixture for you. While we understand Enemy Scaling would help
                with longevity, we thought that this unfairly punishes players
                who level up faster. Part of the fun in action-RPGs is getting
                OP builds and flying through enemies. Therefore, we decided to
                stick with the old formula but also introduce a super hard
                difficulty.
              </span>
              <img src={enemylevels} alt="" />
              <span className="gameplay_section_container_wrapper_currency_text_span">
                For Normal and Legend gameplay, you will have static level
                enemies that (hopefully) progress in difficulty as you play.
                However, upon reaching level 80, you can unlock Impossible
                difficulty which makes all enemies level 100. Honestly, it will
                be almost impossible to beat this difficulty, even with a full
                party. But we put it in there - just for you. =)
              </span>
            </div>
          </div>
        </div>
        <div className="gameplay_video1" style={{ marginTop: "40px" }}>
          {video1Loading && (
            <div className="video_overlay1">
              <div className="lds-ripple">
                <div></div>
                <div></div>
              </div>
              <p>Loading video...</p>
            </div>
          )}
          <video
            autoPlay
            muted
            loop
            onLoadedData={() => {
              setVideo1Loading(false);
            }}
          >
            <source
              src="https://rpc2-game.s3.us-west-1.amazonaws.com/RpcData/Trailers/Trailer_3.webm"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <div className="footer_download">
        <div className="footer_download_container flex-column flex-md-row">
          {/* <div className="footer_download_btn">
          <button className="btn">Download Now</button>
        </div>  */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gameplay;
